import React, { Component } from 'react';

import Layout from '../components/Layout';
import { Headline } from '../components';

class Home extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Headline>Diese Seite existiert nicht.</Headline>
      </Layout>
    );
  }
}

export default Home;
